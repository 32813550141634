var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "delivery-target" }, [
    _c("div", { staticClass: "delivery-target-content" }, [
      _c("div", { staticClass: "delivery-target-title" }, [
        _c("div", { staticClass: "table-title" }, [
          _vm._v(
            "\n                交付报表统计——" +
              _vm._s(
                _vm.deliveryTargetType == 0 || _vm.deliveryTargetType == 3
                  ? "客户"
                  : "成员"
              ) +
              "\n            "
          ),
        ]),
        _c(
          "div",
          { staticClass: "delivery-target-top" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "date-type-dropdown triangle-drop",
                class: _vm.isFocusDate
                  ? "triangle-drop-up"
                  : "triangle-drop-down",
                attrs: { size: "small", trigger: "click" },
                on: { command: _vm.handleDateType },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.dateTypeText[_vm.commandDateType]) +
                      "\n                        "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "date-type-menu",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        class: {
                          "is-active": _vm.commandDateType == "monthrange",
                        },
                        attrs: { command: "monthrange" },
                      },
                      [
                        _vm._v(
                          "\n                            月\n                        "
                        ),
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        class: {
                          "is-active": _vm.commandDateType == "weekrange",
                        },
                        attrs: { command: "weekrange" },
                      },
                      [
                        _vm._v(
                          "\n                            周\n                        "
                        ),
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        class: {
                          "is-active": _vm.commandDateType == "daterange",
                        },
                        attrs: { command: "daterange" },
                      },
                      [
                        _vm._v(
                          "\n                            日\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "date-wrap triangle-drop",
                class: _vm.isFocusDate
                  ? "triangle-drop-up"
                  : "triangle-drop-down",
              },
              [
                _vm.commandDateType == "monthrange" ||
                _vm.commandDateType == "daterange"
                  ? _c("el-date-picker", {
                      attrs: {
                        type: _vm.dateType,
                        placeholder: "选择日期",
                        "range-separator": "~",
                        size: "small",
                        editable: false,
                        align: "center",
                        format: _vm.dateFormat,
                        "value-format": "yyyy-MM-dd",
                        clearable: false,
                        "picker-options": _vm.expireTimeOption,
                        "popper-class": "performance-data-range",
                      },
                      on: {
                        change: _vm.handleDate,
                        focus: _vm.handleFocusDate,
                        blur: _vm.handleBlurDate,
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    })
                  : _vm._e(),
                _vm.commandDateType == "weekrange"
                  ? _c("date-week-range", {
                      attrs: {
                        editable: false,
                        size: "small",
                        "range-separator": "~",
                        clearable: false,
                        align: "center",
                        "picker-options": _vm.expireWeekOption,
                        "popper-class": "performance-data-range",
                      },
                      on: {
                        change: _vm.handleDate,
                        focus: _vm.handleFocusDate,
                        blur: _vm.handleBlurDate,
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.deliveryTargetType != 3
              ? _c(
                  "div",
                  {
                    staticClass: "employee-select-wrap triangle-drop",
                    class: _vm.teamMemberPopoverVisible
                      ? "triangle-drop-up"
                      : "triangle-drop-down",
                  },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          "popper-class":
                            "team-member-popover delivery-team-member-popover",
                          placement: "bottom-end",
                          width: "700",
                          trigger: "click",
                        },
                        model: {
                          value: _vm.teamMemberPopoverVisible,
                          callback: function ($$v) {
                            _vm.teamMemberPopoverVisible = $$v
                          },
                          expression: "teamMemberPopoverVisible",
                        },
                      },
                      [
                        _c("team-member-select", {
                          ref: "teamSelect",
                          attrs: {
                            isAllMembers: true,
                            isShowDimission: false,
                            date: _vm.dateRange,
                            isHistoryData: true,
                            visible: _vm.teamMemberPopoverVisible,
                            selected: _vm.userIds,
                          },
                          on: { "item-select-cb": _vm.employeeSelect },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "employee-select-text",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.selectedMembersText))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "export",
                attrs: { type: "primary", loading: _vm.exportLoading },
                on: { click: _vm.handleExport },
              },
              [_vm._v("导出明细")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "delivery-target-table" },
        [
          _c("delivery-table", {
            attrs: {
              loading: _vm.loading,
              tableData: _vm.tableData,
              extraData: _vm.extraData,
              cacheFilterParams: _vm.cacheFilterParams,
              type: _vm.deliveryTargetType,
              dateType: _vm.dateType,
            },
            on: { fresh: _vm.fresh, jumpDetail: _vm.jumpDetail },
          }),
          _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "el-pagination-workTable",
                  attrs: {
                    layout: "total, sizes, prev, pager, next, slot",
                    "current-page": _vm.page,
                    "page-sizes": [15, 20, 50],
                    "page-size": _vm.pageSize,
                    total: _vm.totalCount,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }