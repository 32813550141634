<template>
    <div class="delivery-target">
        <div class="delivery-target-content">
            <div class="delivery-target-title">
                <div class="table-title">
                    交付报表统计——{{(deliveryTargetType == 0 || deliveryTargetType == 3) ? '客户' : '成员'}}
                </div>
                <div class="delivery-target-top">
                    <el-dropdown
                        class="date-type-dropdown triangle-drop"
                        :class="isFocusDate ? 'triangle-drop-up' : 'triangle-drop-down'"
                        size="small"
                        trigger="click"
                        @command="handleDateType">
                        <span class="el-dropdown-link">
                            {{dateTypeText[commandDateType]}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu
                            class="date-type-menu"
                            slot="dropdown">
                            <el-dropdown-item
                                :class="{'is-active': commandDateType == 'monthrange'}"
                                command="monthrange">
                                月
                            </el-dropdown-item>
                            <el-dropdown-item
                                :class="{'is-active': commandDateType == 'weekrange'}"
                                command="weekrange">
                                周
                            </el-dropdown-item>
                            <el-dropdown-item
                                :class="{'is-active': commandDateType == 'daterange'}"
                                command="daterange">
                                日
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <div class="date-wrap triangle-drop" 
                        :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                        <el-date-picker
                            v-if="commandDateType == 'monthrange' || commandDateType == 'daterange'"
                            v-model="date"
                            :type="dateType"
                            placeholder="选择日期"
                            range-separator="~"
                            size="small"
                            :editable="false"
                            align="center"
                            :format="dateFormat"
                            value-format="yyyy-MM-dd"
                            @change="handleDate"
                            @focus="handleFocusDate"
                            @blur="handleBlurDate"
                            :clearable="false"
                            :picker-options="expireTimeOption"
                            popper-class="performance-data-range">
                        </el-date-picker>

                        <date-week-range 
                            v-if="commandDateType == 'weekrange'"
                            v-model="date" 
                            @change="handleDate"
                            @focus="handleFocusDate"
                            @blur="handleBlurDate"
                            :editable="false"
                            size="small"
                            range-separator="~"
                            :clearable="false"
                            align="center"
                            :picker-options="expireWeekOption"
                            popper-class="performance-data-range"
                            >
                        </date-week-range>
                    </div>

                    <div class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'" v-if="deliveryTargetType != 3">
                        <el-popover
                            popper-class="team-member-popover delivery-team-member-popover"
                            placement="bottom-end"
                            width="700"
                            trigger="click"
                            v-model="teamMemberPopoverVisible"
                            >
                            <team-member-select
                                ref="teamSelect"
                                :isAllMembers="true"
                                :isShowDimission="false"
                                :date="dateRange"
                                :isHistoryData="true"
                                :visible="teamMemberPopoverVisible"
                                :selected="userIds"
                                @item-select-cb="employeeSelect"
                            ></team-member-select>
                            <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                        </el-popover>
                    </div>

                    <el-button 
                        class="export" 
                        type="primary" 
                        :loading="exportLoading"
                        @click="handleExport"
                        >导出明细</el-button>
                </div>
            </div>
            
            <div class="delivery-target-table">
                <delivery-table 
                    :loading="loading" 
                    :tableData="tableData" 
                    :extraData="extraData"
                    :cacheFilterParams="cacheFilterParams"
                    :type="deliveryTargetType" 
                    :dateType="dateType"
                    @fresh="fresh"
                    @jumpDetail="jumpDetail"
                    >
                </delivery-table>
                <div class="work-table-footer">
                    <el-pagination 
                        class="el-pagination-workTable" 
                        layout="total, sizes, prev, pager, next, slot" 
                        :current-page="page" 
                        :page-sizes="[15, 20, 50]" 
                        :page-size="pageSize" 
                        :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        <span class="pagination-text">
                            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { deliveryTarget as deliveryTargetUrl } from '#/js/config/javaApi.json';
    import { currency as currencyFilter } from '#/js/filters/number.js';
    import moment from 'moment';
    import myService from '#/js/service/myService.js';
    import TeamMemberSelect from '#/component/common/team-member-select.vue';
    import DeliveryTable from './layout/deliveryTable.vue';
    import {downloadFile} from '#/js/util/fileUtil.js'
    import dateWeekRange from 'date-week-range';

    export default {
        name: "DeliveryTarget",
        components: {
            TeamMemberSelect,
            DeliveryTable,
            dateWeekRange,
        },
        data() {
            let _minTime = null,
                _maxTime = new Date().getTime();
            return {
                json: {},
                loading: false,
                teamMemberPopoverVisible: false,
                selectedMembersText: '全部成员',
                date: '',
                dateType: "monthrange",
                dateFormat: 'yyyy-MM',
                totalCount: 0,
                page: 1,
                pageSize: 15,
                pagerJump: 0,
                startDate: '',
                endDate: '',
                userIds: [],
                deliveryTargetType: 0,
                tableData: [],
                extraData: [],
                isFocusDate: false, // 用于控制日期的三角
                expireTimeOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let timeRange = 365*24*60*60*1000,
                                _dNow = new Date().getTime();
                            
                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    // disabledDate(date) {
                    //     return date.getTime() >= (new Date(new Date().toDateString()).getTime() + 24*60*60*1000);
                    // },
                },
                expireWeekOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let timeRange = 365*24*60*60*1000,
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        const _lastWeekLastDay = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD');
                        if(_minTime && _maxTime){
                            return (time.getTime() < _minTime || time.getTime() > _maxTime);
                        }
                        if(!_minTime && _maxTime) {
                            return moment(time).isSameOrAfter(_lastWeekLastDay);
                        }
                    },
                    // disabledDate(date) {
                    //     const _lastWeekLastDay = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD');
                    //     return moment(date).isSameOrAfter(_lastWeekLastDay);
                    // },
                },

                dateTypeText: {
                    daterange: '日',
                    weekrange: '周',
                    monthrange: '月',
                    },
                commandDateType: 'monthrange',
                exportLoading: false,
                cacheFilterParams: null,
            }
        },
        computed: {
            firmId() {
                return this.$store.state.user.userInfo.firmId;
            },
            isCaculateOpen() {
                return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
            },
            dateRange() {
                if(this.startDate && this.endDate) {
                    return [this.startDate, this.endDate];
                }else {
                    let startDate = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
                    let endDate = moment(startDate).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD');
                    return [startDate, endDate];
                }
            }

        },
        filters: {
            numPointFilter(val) {
                return val.replace('.00', '');
            },
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:SS')
            }
        },
        created() {
            if(sessionStorage.getItem('deliveryTargetData')) {
            }else if(localStorage.getItem('perpormancesDetailData')) {
                let jsonStr = localStorage.getItem('perpormancesDetailData');
                sessionStorage.setItem('deliveryTargetData', jsonStr);
            }
            // let json = JSON.parse(sessionStorage.getItem('deliveryTargetData'));
            // this.deliveryTargetType = parseInt(json.deliveryTargetType || 0);
            this.json = JSON.parse(sessionStorage.getItem('deliveryTargetData'));
            this.deliveryTargetType = parseInt(this.json.deliveryTargetType || 0);
        },
        mounted() {
            if(this.deliveryTargetType!=3 && !this.$store.state.user.userInfo.isAdministrator && !this.$store.state.user.userInfo.isAdminOrTeamLeader) {
                shortTips("权限不足，请联系管理员")
                setTimeout(() => {
                    location.href = "/Headhunting/#/";
                }, 2000)
            } else {
                // if(sessionStorage.getItem('deliveryTargetData')) {
                // }else if(localStorage.getItem('perpormancesDetailData')) {
                //     let jsonStr = localStorage.getItem('perpormancesDetailData');
                //     sessionStorage.setItem('deliveryTargetData', jsonStr);
                // }
                // // let json = JSON.parse(sessionStorage.getItem('deliveryTargetData'));
                // // this.deliveryTargetType = parseInt(json.deliveryTargetType || 0);
                // this.json = JSON.parse(sessionStorage.getItem('deliveryTargetData'));
                // this.deliveryTargetType = parseInt(this.json.deliveryTargetType || 0);

                this.startDate = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
                this.endDate = moment(this.startDate).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD');
                this.date = [this.startDate, this.endDate];
                // this.getData();
                if(this.deliveryTargetType == 3) {
                    this.getData();
                } else {
                    this.$refs.teamSelect.getHistoryTeamData(true);
                }
                this.loading = true;
                // this.$refs.teamSelect.getHistoryTeamData(true);
            }
        },
        methods: {
            formatLabel(item) {
                if(item.field == 'date') {
                    if(this.JDTDetialType == 0) {
                        return '注册时间';
                    }else if(this.JDTDetialType == 1) {
                        return '分享时间';
                    }else {
                        return '联系时间';
                    }
                }else if(item.field == 'title' || item.field == 'company') {
                    if(this.JDTDetialType == 1) {
                        return '分享' + item.label
                    }else {
                        return item.label
                    }
                    
                }else {
                    return item.label
                }
            },
            handleDateType(type) {
                let today = new Date();

                this.commandDateType = type;
                this.dateType = type == 'weekrange' ? 'daterange' : type;
                // daterange: '日',
                //     weekrange: '周',
                //     monthrange: '月',
                switch(type) {
                    case 'monthrange':
                        this.dateFormat = "yyyy-MM";
                        // date = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
                        this.startDate = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
                        this.endDate = moment(this.startDate).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');
                        break;
                    case 'weekrange':
                        this.dateFormat = "yyyy-MM-dd";
                        // date = moment().subtract(today.getDay() - 1, 'days').format('YYYY-MM-DD');
                        // this.startDate = moment().subtract(today.getDay() - 1, 'days').format('YYYY-MM-DD');
                        // this.endDate = moment(this.startDate).add(6, 'days').format('YYYY-MM-DD');
                        this.startDate = moment().weekday(-7).subtract(1, 'days').format('YYYY-MM-DD');
                        this.endDate = moment(this.startDate).add(6, 'days').format('YYYY-MM-DD');
                        break;
                    case 'daterange':
                        this.dateFormat = "yyyy-MM-dd";
                        // date = moment().subtract(today.getDay() - 1, 'days').format('YYYY-MM-DD');
                        // this.startDate = moment().subtract(today.getDay() - 1, 'days').format('YYYY-MM-DD');
                        this.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                        this.endDate = moment().format('YYYY-MM-DD');
                        break;
                }
                this.date = [this.startDate, this.endDate];
                this.page = 1;
                this.$nextTick(() =>{
                    if(this.$refs.employees) {
                        this.loading = true;
                        this.$refs.employees.getTeamData();
                    }else {
                        this.getData();
                    }
                });
            },
            fresh(obj) {
                this.cacheFilterParams = obj;
                this.page = 1;
                this.getData();
            },
            getData() {
                // console.log(this.json.userIds);
                this.loading = true;
                let data = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    offset: (this.page - 1) * this.pageSize,
                    pageSize: this.pageSize,
                    statisticsTimeType: this.dateType == 'monthrange' ? 1 : 2,
                    // userIds: this.userIds,
                    userIds: this.deliveryTargetType == 3 ? this.json.userIds : this.userIds,
                };
                if(this.cacheFilterParams) {
                    data = {
                        ...data,
                        ...this.cacheFilterParams
                    }
                }

                let url = '';
                if(this.deliveryTargetType == 0 || this.deliveryTargetType == 3) {
                    url = deliveryTargetUrl.customer_data;
                }else {
                    url = deliveryTargetUrl.member_data;
                }

                // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
                // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                const _scrollTop = document.querySelector(".delivery-target").scrollTop;
                if(_scrollTop > 200) {
                    // document.documentElement.scrollTop = 0;
                    // document.body.scrollTop = 0;
                    // console.log('scroll top');
                    document.querySelector(".delivery-target").scrollTop = 0;
                }

                _request({
                    method: 'POST',
                    url: url,
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    data: data
                }).then(res =>{
                    console.log(res)
                    let list = res.data || [];
                    list.forEach(item => {
                        item.month = (new Date(this.startDate).getMonth() +1) + '月';
                        item.businessGroupName = item.businessGroupName || '-';
                        if(!item.mc || item.mc.length == 0) {
                            item.mc = '-';
                        }else {
                            item.mc = item.mc.join('，') || '-';
                        }

                        if(!item.locations || item.mc.locations == 0) {
                            item.locations = '-';
                        }else {
                            item.locations = item.locations.join('、') || '-';
                        }
                        
                        if(!item.firmTeamNames || item.firmTeamNames.length == 0) {
                            item.firmTeamNames = '-';
                        }else {
                            item.firmTeamNames = item.firmTeamNames.join('，') || '-';
                        }
                        
                        if(item.firstLevelTeams && item.firstLevelTeams.length > 0) {
                            let firstLevelTeams = [];
                            item.firstLevelTeams.forEach(team =>{
                                firstLevelTeams.push(team.teamName);
                            })
                            item.firstLevelTeams = firstLevelTeams.join('，') || '-';
                        }else {
                            item.firstLevelTeams = '-';
                        }
                        if(item.secondLevelTeams && item.secondLevelTeams.length > 0) {
                            let secondLevelTeams = [];
                            item.secondLevelTeams.forEach(team =>{
                                secondLevelTeams.push(team.teamName);
                            })
                            item.secondLevelTeams = secondLevelTeams.join('，') || '-';
                        }else {
                            item.secondLevelTeams = '-';
                        }
                    })
                    this.totalCount = res.count;
                    this.tableData = list;
                    this.extraData = res.extraData || [];
                    if(this.cacheFilterParams) {
                        this.extraData.forEach(item => {
                            // console.log(item)
                            if(this.cacheFilterParams.customerIds && this.cacheFilterParams.customerIds.includes(item.customerId)) {
                                item.isSelect = true;
                            }

                            if(item.customerDeparments){
                                item.customerDeparments.forEach(dItem => {
                                if(this.cacheFilterParams.customerDeparmentIds && this.cacheFilterParams.customerDeparmentIds.includes(dItem.id)) {
                                        dItem.isSelect = true;
                                    }
                                });
                            }

                            if(item.jobSeries){
                                item.jobSeries.forEach(dItem => {
                                if(this.cacheFilterParams.jobSerieIds && this.cacheFilterParams.jobSerieIds.includes(dItem.id)) {
                                        dItem.isSelect = true;
                                    }
                                });
                            }

                            if(item.businessGroups){
                                item.businessGroups.forEach(dItem => {
                                if(this.cacheFilterParams.businessGroupIds && this.cacheFilterParams.businessGroupIds.includes(dItem.id)) {
                                        dItem.isSelect = true;
                                    }
                                });
                            }
                        })
                    }
                    this.loading = false;

                    this.$nextTick(() => {
                        // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
                        const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                        if(_scrollTop > 200) {
                            document.documentElement.scrollTop = 0;
                            document.body.scrollTop = 0;
                            console.log('scroll top');
                        }
                    })
                });
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.getData();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.getData();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.getData();
            },
            columnFormatter(column, cellValue) {
                if(column == 'offerTime' || column == 'hireTime') {
                    return cellValue ? moment(cellValue).format('YYYY-MM-DD') : ''
                }else if(column == 'serviceCharge' || column == 'performance') {
                    return currencyFilter(cellValue, '', 0);
                }else {
                    return cellValue;

                }
            },
            handleCellMouseEnter(row, column, cell, event, flag) {
                let popperEleArr = document.getElementsByClassName('el-tooltip__popper is-light');
                if(popperEleArr && popperEleArr.length > 0) {
                    Array.prototype.forEach.call(popperEleArr, (popperEle) => {
                        popperEle.innerText = cell.innerText;
                        if(popperEle.innerText.indexOf('\n') > -1) {
                            let html = popperEle.innerText.replace('\n', '<br />');
                            popperEle.innerHTML = popperEle.innerHTML.replace(popperEle.innerText, html);
                        }
                    })
                }else {
                    if(!flag) {
                        setTimeout(() =>{
                            this.handleCellMouseEnter(row, column, cell, event, true);
                        }, 100)
                    }
                }
            },

            handleDate(value) {
                console.log(value)
                switch(this.commandDateType) {
                    case 'monthrange':
                        this.dateFormat = "yyyy-MM";
                        this.startDate = moment(value[0]).format('YYYY-MM-DD');
                        this.endDate = moment(value[1]).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');
                        // this.endDate = moment(this.startDate).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD');
                        break;
                    case 'weekrange':
                        this.dateFormat = "yyyy-MM-dd";
                        this.startDate = moment(value[0]).format('YYYY-MM-DD');
                        this.endDate = moment(value[1]).format('YYYY-MM-DD');
                        break;
                    case 'daterange':
                        this.dateFormat = "yyyy-MM-dd";
                        // value = moment(value).subtract(1, 'days').format('YYYY-MM-DD');
                        // this.startDate = value;
                        // this.endDate = moment(value).add(6, 'days').format('YYYY-MM-DD');
                        this.startDate = value[0];
                        this.endDate = value[1];
                        break;
                }
                this.page = 1;
                this.loading = true;
                // this.$refs.teamSelect.getHistoryTeamData(true);
                if(this.deliveryTargetType != 3) {
                    this.$refs.teamSelect.getHistoryTeamData(true);
                } else {
                    this.getData();
                }
            },
            handleFocusDate() {
                this.isFocusDate = true;
            },
            handleBlurDate() {
                this.isFocusDate = false;
            },
         
            employeeSelect(userIds, text) {
                this.userIds = userIds;
                this.selectedMembersText = text;
                this.page = 1;
                this.getData();
                this.teamMemberPopoverVisible = false;
            },
            // 导出
            handleExport() {
                let params = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    offset: (this.page - 1) * this.pageSize,
                    pageSize: this.pageSize,
                    statisticsTimeType: this.dateType == 'monthrange' ? 1 : 2,
                    // userIds: this.userIds,
                    userIds: this.deliveryTargetType == 3 ? this.json.userIds : this.userIds,

                    customerDeparmentIds: null,
                    customerIds: null,
                    jobSerieIds: null,
                };

                if(this.cacheFilterParams) {
                    params = {
                        ...params,
                        ...this.cacheFilterParams,
                    };
                }

                let url = '';
                if(this.deliveryTargetType == 0) {
                    url = deliveryTargetUrl.customer_export;
                } else if(this.deliveryTargetType == 3) {
                    url = deliveryTargetUrl.single_customer_export;
                } else {
                    url = deliveryTargetUrl.member_export;
                }

                this.exportLoading = true;

                _request({
                    method: 'POST',
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    url: url,
                    responseType: 'blob',
                    data: params
                }).then(res =>{
                    downloadFile(res);
                }).catch((err) => {
                    shortTips(err.message || '系统异常');
                }).finally(() => {
                    this.exportLoading = false;
                })
            },
            jumpDetail(detailType, id, pmId, jobId) {
                //detailType: 1-推荐 2-面试 3-offer   id:客户表为projectId，成员表为userId
                let json = {};
                let { userIds, startDate, endDate } = this;
                json = { userIds, startDate, endDate };
                if(this.deliveryTargetType == 0 || this.deliveryTargetType == 3) {
                    json.projectId = id;
                    json.userId = pmId;
                    json.recommendFlowStepType = detailType;
                    json.statisticsTimeType = 1;
                    json.jobId = jobId;
                    if (this.dateType == 'weekrange') {
                        json.statisticsTimeType = 2;
                    }else if (this.dateType == 'daterange') {
                        json.statisticsTimeType = 3;
                    }
                    sessionStorage.removeItem('deliveryTargetDetailData');
                    localStorage.setItem('deliveryTargetDetailData', JSON.stringify(json));
                    // window.open('/Headhunting/MyCompany.html#/deliveryTargetDetail', '_blank');
                    window.open('/#/deliveryTargetDetail', '_blank');
                }else {
                    json.userIds = [id];
                    if(detailType == 3) {
                        sessionStorage.removeItem('offerReportData');
                        localStorage.setItem('offerReportData', JSON.stringify(json));
                        // window.open('/Headhunting/MyCompany.html#/offerReport?type=offer');
                        window.open('/#/offerReport?type=offer');
                    }else {
                        json.key = 'recommendationDetails';
                        if(detailType == 1) {
                            json.achievementDetailType = '4';
                        }else if(detailType == 2) {
                            json.achievementDetailType = '7';
                        }
                        sessionStorage.removeItem('perpormancesDetailData');
                        localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
                        // window.open('/Headhunting/MyCompany.html#/performancesDetail', '_blank');
                        window.open('/#/performancesDetail', '_blank');
                    }

                }
            }
        }
    }
</script>

<style lang="scss" scope>
// @import 'date-week-range/dist/date-picker.css';
    .delivery-target {
        // background-color: #f9f9f9;
        padding: 20px;
        height: 100%;
        overflow-y: auto;

        &-content {
            padding-bottom: 38px;
            background-color: #fff;
            border-radius: 8px;
            min-height: calc(100vh - 90px);
        }

        &-title {
            display: flex;
            justify-content: space-between;
            line-height: 32px;
            padding: 30px 30px 0;
            margin-bottom: 8px;

            .table-title {
                font-size: 28px;
                color: #666;
                line-height: 32px;
            }

            & > span {
                color: $primary;
            }

            &-btn {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 32px;
            }
        }

        &-top {
            display:flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
        }

        .date-type-dropdown.el-dropdown {
            margin-right: 20px;
            height: 32px;
            width: 70px;
            // border-radius: 16px;
            border-radius: 4px;
            border: 1px solid #d3d3d3;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 30px;
            font-size: 14px;
            color: #76838f;
            cursor: pointer;
            background-color: #fff;

            &:hover {
                background-color: #38BC9D;
                border-color:#38BC9D;
                color: #fff;
            }

            .el-icon--right {
                color: transparent;
            }
        }

        .cell-share-data {
            width: 136px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .table-header-tip-cell .table-header-tip-icon {
            top: 0;
        }

        .el-table thead {
            color: #666;
            font-weight: bold;
        }

        .el-table thead.is-group th {
            // border: 0 none;
            // background-color: #f8f8f8;
            background-color: #F0F4F7;
        }

        .el-table thead.is-group tr:first-child th {
            text-align: center;
        }

        .el-table--striped .el-table__body tr.el-table__row--striped td {
            // background-color: #f8f8f8;
            background-color: #F0F4F7;
        }

        .el-table td {
            // border: 0 none;
        }

        .el-table th, .el-table td {
            padding: 8px 10px;
        }

        .date-wrap {
            position: relative;
            cursor: pointer;
            // width: 220px;
            width: 260px;
            
            .el-date-editor {
                height: 32px;
                // width: 220px;
                width: 260px;
                // border-radius: 16px;
                .el-input__inner {
                    // border-radius: 16px;
                    padding-left: 20px;
                    padding-right: 20px;
                    cursor: pointer;
                }
                &:hover {
                    .el-input__inner {
                        border-color:#38BC9D;
                        background: #38BC9D;
                        color: #fff;
                    }
                }
                .el-input__prefix, .el-input__suffix {
                    display: none;
                }
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                pointer-events: none;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }

        .employee-select-wrap{
            position: relative;
            margin-left: 20px;

            .employee-select-text {
                display: inline-block;
                width: 116px;
                height: 32px;
                line-height: 32px;
                border: 1px solid #d3d3d3;
                // border-radius: 16px;
                padding: 0 36px 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
            }

            &:hover{
                .employee-select-text{
                    background: #38BC9D;
                    border-color: #38BC9D;
                    color: #fff;
                }

            }
            &.triangle-drop-up{
                &::after{
                    border-bottom: 12px solid #fff;
                    margin-top: -3px;
                }
                .employee-select-text{
                    background: #38BC9D;
                    border-color: #38BC9D;
                    color: #fff;
                }
            }
        }
        .delivery-target-top{
            position: relative;
        }
        .export{
            // position: absolute;
            // right: 0;
            margin-left: 20px;
        }

        .work-table-footer {
            margin: 35px 35px 0 0;
            overflow: hidden;
        }
    }
</style>
<style lang="scss">
    .team-member-popover.delivery-team-member-popover {
        transform: translateX(0);
    }
    .layout-transition {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .performance-data-range.el-date-range-picker {
        .popper__arrow {
            left: 50% !important;
        }
    }
    .offer-edit-log-popper.el-popper {
        padding: 0;
        .offer-edit-log-list {
            max-height: 560px;
            overflow: hidden;
            overflow-y: auto;
            padding: 20px;
            .offer-edit-log-item {
                p {
                    color: #999;
                    line-height: 20px;
                    margin-bottom: 0;
                }
                .offer-info-time {
                    margin-bottom: 4px;
                    color: #666;
                }
                .offer-log-title {
                    color: #333;
                    line-height: 20px;
                    .offer-log-updator {
                        color: $primary;
                        margin-right: 5px;
                    }
                }
                & + .offer-edit-log-item {
                    margin-top: 20px;
                }
            }
        }
    }

    .date-type-menu.el-dropdown-menu {
        width: 70px;
        margin-top: 6px;
        padding: 0;
        .el-dropdown-menu__item {
            line-height: 40px;
            font-size: 14px;
            width: 70px;
            text-align: center;
            &.is-active {
                background-color: #ebf8f5;
                color: #60c9b1;
            }
        }
    }
</style>