<template>
    <div class="delivery-table">
        <!-- 客户-月 -->
        <el-table
            v-if="(type == 0 || type == 3) && dateType == 'monthrange'"
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column label="基本信息" width="1120" fixed>
            <!-- <el-table-column label="基本信息"> -->
                <el-table-column
                    prop="time"
                    align="left"
                    width="110"
                    :show-overflow-tooltip="true"
                    label="时间"
                >
                </el-table-column>
                <el-table-column
                    prop="projectName"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="项目名称"
                >
                </el-table-column>
                <el-table-column
                    prop="customerName"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="客户"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="true"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="customerArray"
                            @selectChange="selectCustomerChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="customerDomain"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="所属领域"
                    v-if="isCFUser"
                >
                    <template slot-scope="{ row }">{{row.customerDomain || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="businessGroupName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="业务分组"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="businessGroupArray"
                            @selectChange="businessGroupChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="locations"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="地点"
                >
                </el-table-column>
                <el-table-column
                    prop="customerDeparmentChain"
                    align="left"
                    width="170"
                    :show-overflow-tooltip="true"
                    label="事业群/事业部"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="departmentArray"
                            @selectChange="selectDepartmentChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="jobSerieName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="职位品类">
                    <template slot="header" slot-scope="{column}">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="jobArray"
                            @selectChange="selectJobChange"
                        ></select-popover>
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="jobName"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="职位名称"
                    v-if="isCFUser"
                >
                </el-table-column>
                <el-table-column
                    prop="jobSeriesName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="职位类别">
                    <template slot="header" slot-scope="{column}">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="jobArray"
                            @selectChange="selectJobChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="{ row }">{{row.jobSeriesName || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="pm"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="PM"
                    v-if="type == 0"
                >
                </el-table-column>
                <!-- <el-table-column
                    prop="mc"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="MC"
                    v-if="type == 0"
                >
                </el-table-column> -->
                <el-table-column
                    prop="mc"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="UL"
                    v-if="type == 0"
                >
                </el-table-column>
                <el-table-column
                    prop="firmTeamNames"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="所属团队"
                    v-if="type == 0"
                >
                </el-table-column>
                <el-table-column
                    prop="headCount"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="HC数量"
                >
                    <template slot-scope="{ row }">{{row.headCount || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="accessDate"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="接入时间"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.accessDate">{{ scope.row.accessDate | accessDateFilter }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="optimisticDegree"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="乐观程度"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.optimisticDegree == '1'">乐观</span>
                        <span v-else-if="scope.row.optimisticDegree == '2'">不乐观</span>
                        <span v-else-if="scope.row.optimisticDegree == '3'">谨慎</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nodeImportance"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="节点重要性"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.nodeImportance == '1'">A类</span>
                        <span v-else-if="scope.row.nodeImportance == '2'">B类</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="交付目标">
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfRecommandation"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                </el-table-column>
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfInterview"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="面试"
                >
                </el-table-column>
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfOffer"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                </el-table-column>
            </el-table-column>
            <el-table-column label="实际交付情况">
                <el-table-column
                    prop="recommendationCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(1, row, row.pmId)"
                            >{{ row.recommendationCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="一面"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(2, row, row.pmId)"
                            >{{ row.arrangeInterviewCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewPercent"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="一面率"
                >
                </el-table-column>
                <el-table-column
                    prop="offerCount"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(3, row, row.pmId)"
                            >{{ row.offerCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="serviceCharge"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="服务费"
                >
                </el-table-column>
            </el-table-column>
        </el-table>

        <!-- 客户-周日 -->
        <el-table
            v-if="
                (type == 0 || type == 3) &&
                (dateType == 'weekrange' || dateType == 'daterange')
            "
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column label="基本信息" width="1000" fixed>
            <!-- <el-table-column label="基本信息"> -->
                <el-table-column
                    prop="time"
                    align="left"
                    width="110"
                    :show-overflow-tooltip="true"
                    label="时间"
                >
                </el-table-column>
                <el-table-column
                    prop="projectName"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="项目名称"
                >
                </el-table-column>
                <el-table-column
                    prop="customerName"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="客户"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="true"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="customerArray"
                            @selectChange="selectCustomerChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="customerDomain"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="所属领域"
                    v-if="isCFUser"
                >
                    <template slot-scope="{ row }">{{row.customerDomain || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="businessGroupName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="业务分组"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="businessGroupArray"
                            @selectChange="businessGroupChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="locations"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="地点"
                >
                </el-table-column>
                <el-table-column
                    prop="customerDeparmentChain"
                    align="left"
                    width="170"
                    :show-overflow-tooltip="true"
                    label="事业群/事业部"
                >
                    <template slot="header" slot-scope="{ column }">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="departmentArray"
                            @selectChange="selectDepartmentChange"
                        ></select-popover>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="jobSerieName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="职位品类">
                    <template slot="header" slot-scope="{column}">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="jobArray"
                            @selectChange="selectJobChange"
                        ></select-popover>
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="jobSeriesName"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="职位类别">
                    <template slot="header" slot-scope="{column}">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="false"
                            :title="column.label"
                            :enableSearch="true"
                            :selectItem="jobArray"
                            @selectChange="selectJobChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="{ row }">{{row.jobSeriesName || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="pm"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="PM"
                    v-if="type == 0"
                >
                </el-table-column>
                <!-- <el-table-column
                    prop="mc"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="MC"
                    v-if="type == 0"
                >
                </el-table-column> -->
                <el-table-column
                    prop="mc"
                    align="left"
                    width="68"
                    :show-overflow-tooltip="true"
                    label="UL"
                    v-if="type == 0"
                >
                </el-table-column>
                <el-table-column
                    prop="firmTeamNames"
                    align="left"
                    width="180"
                    :show-overflow-tooltip="true"
                    label="所属团队"
                    v-if="type == 0"
                >
                </el-table-column>
                <el-table-column
                    prop="headCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="HC数量"
                >
                    <template slot-scope="{ row }">{{row.headCount || '-'}}</template>
                </el-table-column>
                <el-table-column
                    prop="accessDate"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="接入时间"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.accessDate">{{ scope.row.accessDate | accessDateFilter }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="optimisticDegree"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="乐观程度"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.optimisticDegree == '1'">乐观</span>
                        <span v-else-if="scope.row.optimisticDegree == '2'">不乐观</span>
                        <span v-else-if="scope.row.optimisticDegree == '3'">谨慎</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nodeImportance"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="节点重要性"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.nodeImportance == '1'">A类</span>
                        <span v-else-if="scope.row.nodeImportance == '2'">B类</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <!-- <el-table-column label="交付目标">
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfRecommandation"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                </el-table-column>
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfInterview"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="面试"
                >
                </el-table-column>
                <el-table-column
                    :key="Math.random()"
                    prop="goalOfOffer"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                </el-table-column>
            </el-table-column> -->
            <el-table-column label="实际交付情况">
                <el-table-column
                    prop="recommendationCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(1, row, row.pmId)"
                            >{{ row.recommendationCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="一面"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(2, row, row.pmId)"
                            >{{ row.arrangeInterviewCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewPercent"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="一面率"
                >
                </el-table-column>
                <el-table-column
                    prop="offerCount"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                    <template slot-scope="{ row }">
                        <span
                            class="num"
                            @click="jumpDetail(3, row, row.pmId)"
                            >{{ row.offerCount }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="serviceCharge"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="服务费"
                >
                </el-table-column>
            </el-table-column>
        </el-table>

        <!-- 成员-月 -->
        <el-table
            v-if="type == 1 && dateType == 'monthrange'"
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column label="基本信息">
                <el-table-column
                    prop="time"
                    align="left"
                    width="156"
                    :show-overflow-tooltip="true"
                    label="时间"
                >
                </el-table-column>
                <el-table-column
                    prop="mc"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="MC"
                >
                </el-table-column>
                <el-table-column
                    prop="firmTeamNames"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="所属一级团队"
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="成员"
                >
                </el-table-column>
            </el-table-column>
            <el-table-column label="交付目标">
                <el-table-column
                    prop="goalOfRecommandation"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                </el-table-column>
                <el-table-column
                    prop="goalOfInterview"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="面试"
                >
                </el-table-column>
                <el-table-column
                    prop="goalOfOffer"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                </el-table-column>
            </el-table-column>
            <el-table-column label="实际交付情况">
                <el-table-column
                    prop="recommendationCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                    <template slot-scope="{ row }">
                        <!-- <a class="num" href="javascript;;" target="_blank">{{row.recommendationCount}}</a> -->
                        <span class="num" @click="jumpDetail(1, row)">{{
                            row.recommendationCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="一面"
                >
                    <template slot-scope="{ row }">
                        <!-- <a class="num" href="javascript;;" target="_blank">{{row.arrangeInterviewCount}}</a> -->
                        <span class="num" @click="jumpDetail(2, row)">{{
                            row.arrangeInterviewCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewPercent"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="一面率"
                >
                </el-table-column>
                <el-table-column
                    prop="offerCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                    <template slot-scope="{ row }">
                        <!-- <a class="num" href="javascript;;" target="_blank">{{row.offerCount}}</a> -->
                        <span class="num" @click="jumpDetail(3, row)">{{
                            row.offerCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="obsoletedCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="飞单数"
                >
                </el-table-column>
            </el-table-column>
        </el-table>

        <!-- 成员-周日 -->
        <el-table
            v-if="
                type == 1 &&
                (dateType == 'weekrange' || dateType == 'daterange')
            "
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column label="基本信息">
                <el-table-column
                    prop="time"
                    align="left"
                    width="156"
                    :show-overflow-tooltip="true"
                    label="时间"
                >
                </el-table-column>
                <el-table-column
                    prop="mc"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="MC"
                >
                </el-table-column>
                <el-table-column
                    prop="firmTeamNames"
                    align="left"
                    width="150"
                    :show-overflow-tooltip="true"
                    label="所属一级团队"
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="left"
                    width="120"
                    :show-overflow-tooltip="true"
                    label="成员"
                >
                </el-table-column>
            </el-table-column>

            <el-table-column label="实际数据">
                <el-table-column
                    prop="recommendationCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="推荐"
                >
                    <template slot-scope="{ row }">
                        <span class="num" @click="jumpDetail(1, row)">{{
                            row.recommendationCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="一面"
                >
                    <template slot-scope="{ row }">
                        <span class="num" @click="jumpDetail(2, row)">{{
                            row.arrangeInterviewCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrangeInterviewPercent"
                    align="left"
                    width="100"
                    :show-overflow-tooltip="true"
                    label="一面率"
                >
                </el-table-column>
                <el-table-column
                    prop="offerCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="Offers"
                >
                    <template slot-scope="{ row }">
                        <span class="num" @click="jumpDetail(3, row)">{{
                            row.offerCount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="obsoletedCount"
                    align="left"
                    width="80"
                    :show-overflow-tooltip="true"
                    label="飞单数"
                >
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import moment from 'moment';
import SelectPopover from '#/component/common/select-popover.vue';
export default {
    name: "delivery-table",
    props: {
        tableData: Array,
        extraData: {
            type: Array,
            default: function() {
                return []
            }
        },
        cacheFilterParams: Object || null,
        loading: Boolean,
        type: Number,
        dateType: String,
    },
    components: {
        SelectPopover,
    },
    data() {
        return {};
    },
    filters: {
        accessDateFilter(str) {
            // console.log(str)
            let result = moment(str).format('YYYY-MM-DD');
            return result;
        }
    },
    computed: {
        // 客户
        customerArray() {
            let _customerIds = [];
            let _customer = [];
            this.extraData.forEach((item) => {
                // // if( this.isLegalCustomer(item.customerId) ) {
                // _customer.push({
                //     ...item,
                //     label: item.customerName,
                //     count: item.total,
                // });
                // // }

                // 去除数组对象里面的重复对象
                if(item.customerId && _customerIds.indexOf(item.customerId) == -1) {
                    _customerIds.push(item.customerId)
                    _customer.push({
                        ...item,
                        label: item.customerName,
                        count: item.total,
                    });
                }
            });
            return _customer;
        },
        // 事业群/事业部
        departmentArray() {
            let _customerDeparments = [];
            this.extraData.forEach((item) => {
                // if(item.customerDeparments && ( (this.cacheFilterParams && this.cacheFilterParams.customerIds && this.cacheFilterParams.customerIds.includes(item.customerId)) || !this.cacheFilterParams || !this.cacheFilterParams.customerDeparmentIds)) {
                if (this.isLegalCustomer(item.customerId)) {
                    item.customerDeparments && item.customerDeparments.forEach((subItem) => {
                        const _index = _customerDeparments.findIndex(
                            (_item) => _item.id == subItem.id
                        );
                        if (_index < 0) {
                            _customerDeparments.push({
                                ...subItem,
                                label: subItem.chain,
                            });
                        }
                    });
                }
            });
            return _customerDeparments;
        },
        // 职位
        jobArray() {
            let _jobSeries = [];
            this.extraData.forEach((item) => {
                if (this.isLegalCustomer(item.customerId)) {
                    item.jobSeries && item.jobSeries.forEach((subItem) => {
                        const _index = _jobSeries.findIndex(
                            (_item) => _item.id == subItem.id
                        );
                        if (_index < 0) {
                            _jobSeries.push({
                                ...subItem,
                                label: subItem.name,
                            });
                        }
                    });
                }
            });
            return _jobSeries;
        },
        // jobArray() {
        //     let _jobSerieIds = [];
        //     let _jobSeries = [];
        //     this.extraData.forEach((item) => {
        //         // console.log(item);
        //         // _jobSeries.push({
        //         //     ...item,
        //         //     label: item.jobSeriesName,
        //         // });

        //         // 去除数组对象里面的重复对象
        //         if(item.jobSerieId && _jobSerieIds.indexOf(item.jobSerieId) == -1) {
        //             _jobSerieIds.push(item.jobSerieId)
        //             _jobSeries.push({
        //                 ...item,
        //                 label: item.jobSeriesName,
        //             });
        //         }
        //     });
        //     // console.log(_jobSeries);
        //     return _jobSeries;
        // },
        businessGroupArray() {
            let aBusinessGroup = [];
            this.extraData.forEach((item) => {
                if (this.isLegalCustomer(item.customerId)) {
                    item.businessGroups && item.businessGroups.forEach((subItem) => {
                        const _index = aBusinessGroup.findIndex(
                            (_item) => _item.id == subItem.id
                        );
                        if (_index < 0) {
                            aBusinessGroup.push({
                                ...subItem,
                                label: subItem.name,
                            });
                        }
                    });
                }
            });
            return aBusinessGroup;
        },
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser || false;
        },
    },
    methods: {
        isLegalCustomer(customerId) {
            return (
                !this.cacheFilterParams ||
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds &&
                    (this.cacheFilterParams.customerIds.includes(customerId) ||
                        this.cacheFilterParams.customerIds.length == 0)) ||
                (this.cacheFilterParams && !this.cacheFilterParams.customerIds)
            );
        },
        isLegalCustomer_b(customerId) {
            return (
                !this.cacheFilterParams ||
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds) ||
                (this.cacheFilterParams && !this.cacheFilterParams.customerIds)
            );
        },
        selectCustomerChange(_, cumtomer) {
            console.log(cumtomer);
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (cumtomer.length > 0) {
                cumtomer.forEach((item) => {
                    _customerIds.push(item.customerId);
                });
                _customerDeparmentIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerDeparmentIds) ||
                    [];
                _jobSerieIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.jobSerieIds) ||
                    [];
                _businessGroupIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams._businessGroupIds) ||
                    [];
            }

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            this.$emit("fresh", obj);
        },
        selectDepartmentChange(_, department) {
            console.log(department);
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (department.length > 0) {
                _customerDeparmentIds = department.map((item) => item.id);
            }
            _customerIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds) ||
                [];
            _jobSerieIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.jobSerieIds) ||
                [];
            _businessGroupIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.businessGroupIds) ||
                [];

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            this.$emit("fresh", obj);
        },
        selectJobChange(_, job) {
            console.log(job);
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (job.length > 0) {
                _jobSerieIds = job.map((item) => item.id);
            }
            _customerIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds) ||
                [];
            _customerDeparmentIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerDeparmentIds) ||
                [];
            _businessGroupIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.businessGroupIds) ||
                [];

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            this.$emit("fresh", obj);
        },
        // selectJobChange(_, job) {
        //     console.log(job);
        //     let _customerIds = [],
        //         _customerDeparmentIds = [],
        //         _jobSerieIds = [],
        //         _businessGroupIds = [];

        //     if (job.length > 0) {
        //         job.forEach((item) => {
        //             _jobSerieIds.push(item.jobSerieId);
        //         });
        //         _customerIds =
        //             (this.cacheFilterParams &&
        //                 this.cacheFilterParams.customerIds) ||
        //             [];
        //         _customerDeparmentIds =
        //             (this.cacheFilterParams &&
        //                 this.cacheFilterParams.customerDeparmentIds) ||
        //             [];
        //         _businessGroupIds =
        //             (this.cacheFilterParams &&
        //                 this.cacheFilterParams.businessGroupIds) ||
        //             [];
        //         }

        //     const obj = {
        //         customerIds: _customerIds,
        //         customerDeparmentIds: _customerDeparmentIds,
        //         jobSerieIds: _jobSerieIds,
        //         businessGroupIds: _businessGroupIds,
        //     };
        //     this.$emit("fresh", obj);
        // },
        businessGroupChange(_, businessGroup) {
            let _businessGroup = [];

            if (businessGroup.length > 0) {
                _businessGroup = businessGroup.map((item) => item.id);
            }

            const obj = {
                customerIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerIds) ||
                    [],
                customerDeparmentIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerDeparmentIds) ||
                    [],
                jobSerieIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.jobSerieIds) ||
                    [],
                businessGroupIds: _businessGroup,
            };
            this.$emit("fresh", obj);
        },
        formatTeam(firmTeams) {
            let result = [];
            firmTeams &&
                firmTeams.forEach((item) => {
                    result.push(item.teamName);
                });
            return result.join("、");
        },
        jumpDetail(detailType, row, pmId) {
            //detailType: 0-推荐 1-面试 2-offer
            // id:客户表为projectId，成员表为userId
            let id = row.userId;
            if(this.$parent.deliveryTargetType == 0 || this.$parent.deliveryTargetType == 3) {
                id = row.projectId;
            }
            this.$emit("jumpDetail", detailType, id, pmId, row.jobId);
        },
    },
};
</script>

<style lang="scss">
.delivery-table {
    /*解决固定列遮盖底部滚定条导致滚动条无法滚动的问题*/
    .el-table__body-wrapper{
        z-index: 2
    }

    // 解决el-table-column设置了fixed和width,当超出宽度的字段列值没有显示问题
    .el-table .el-table__cell.is-hidden > * {
        visibility: visible;
    }
    .el-table th.is-hidden > *, .el-table td.is-hidden > * {
        visibility: visible;
    }
}
</style>

<style lang="scss" scoped>
.delivery-table {
    .ell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .drop-down-body {
        width: 272px;
        height: 381px;
        background: #ffffff;
        box-shadow: 0px 5px 18px 0px rgba(198, 199, 198, 0.8);
        .drop-search {
            width: 100%;
            padding: 18px 18px 0 18px;
        }
        .drop-main {
            height: 295px;
            overflow-y: auto;
            padding: 0 18px;
            .select-item {
                display: block;
                width: 100%;
                height: 38px;
                line-height: 38px;
                margin-bottom: 0;
                // background: burlywood;
                .el-checkbox__label {
                    color: #606266;
                    display: inline-block;
                    max-width: 203px;
                    font-size: 14px;
                    vertical-align: middle;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .drop-footer {
            width: 100%;
            background: #f8f8f8;
            padding: 6px 18px;
            text-align: right;
            .el-button--default,
            .el-button--default:hover,
            .el-button--default:active,
            .el-button--default:focus {
                background-color: white;
            }
        }
    }
    .el-dropdown-menu {
        padding: 0;
    }
    .el-dropdown-link {
        color: #909399;
    }
    .num {
        color: #38bc9d;
        cursor: pointer;
    }
}
</style>