var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delivery-table" },
    [
      (_vm.type == 0 || _vm.type == 3) && _vm.dateType == "monthrange"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "基本信息", width: "1120", fixed: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "time",
                      align: "left",
                      width: "110",
                      "show-overflow-tooltip": true,
                      label: "时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "projectName",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "项目名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerName",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "客户",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: true,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.customerArray,
                                },
                                on: { selectChange: _vm.selectCustomerChange },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1204060694
                    ),
                  }),
                  _vm.isCFUser
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "customerDomain",
                          align: "left",
                          width: "120",
                          "show-overflow-tooltip": true,
                          label: "所属领域",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _vm._v(_vm._s(row.customerDomain || "-")),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          214230646
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "businessGroupName",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "业务分组",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.businessGroupArray,
                                },
                                on: { selectChange: _vm.businessGroupChange },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1897618357
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "locations",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "地点",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerDeparmentChain",
                      align: "left",
                      width: "170",
                      "show-overflow-tooltip": true,
                      label: "事业群/事业部",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.departmentArray,
                                },
                                on: {
                                  selectChange: _vm.selectDepartmentChange,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2701558973
                    ),
                  }),
                  _vm.isCFUser
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "jobName",
                          align: "left",
                          width: "120",
                          "show-overflow-tooltip": true,
                          label: "职位名称",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "jobSeriesName",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "职位类别",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.jobArray,
                                },
                                on: { selectChange: _vm.selectJobChange },
                              }),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.jobSeriesName || "-"))]
                          },
                        },
                      ],
                      null,
                      false,
                      3655361437
                    ),
                  }),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "pm",
                          align: "left",
                          width: "68",
                          "show-overflow-tooltip": true,
                          label: "PM",
                        },
                      })
                    : _vm._e(),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "mc",
                          align: "left",
                          width: "68",
                          "show-overflow-tooltip": true,
                          label: "UL",
                        },
                      })
                    : _vm._e(),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "firmTeamNames",
                          align: "left",
                          width: "120",
                          "show-overflow-tooltip": true,
                          label: "所属团队",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "headCount",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "HC数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.headCount || "-"))]
                          },
                        },
                      ],
                      null,
                      false,
                      2609910329
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "accessDate",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "接入时间",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.accessDate
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("accessDateFilter")(
                                          scope.row.accessDate
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1062946101
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "optimisticDegree",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "乐观程度",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.optimisticDegree == "1"
                                ? _c("span", [_vm._v("乐观")])
                                : scope.row.optimisticDegree == "2"
                                ? _c("span", [_vm._v("不乐观")])
                                : scope.row.optimisticDegree == "3"
                                ? _c("span", [_vm._v("谨慎")])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3483134741
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nodeImportance",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "节点重要性",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.nodeImportance == "1"
                                ? _c("span", [_vm._v("A类")])
                                : scope.row.nodeImportance == "2"
                                ? _c("span", [_vm._v("B类")])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      750936966
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "交付目标" } },
                [
                  _c("el-table-column", {
                    key: Math.random(),
                    attrs: {
                      prop: "goalOfRecommandation",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                  }),
                  _c("el-table-column", {
                    key: Math.random(),
                    attrs: {
                      prop: "goalOfInterview",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "面试",
                    },
                  }),
                  _c("el-table-column", {
                    key: Math.random(),
                    attrs: {
                      prop: "goalOfOffer",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "实际交付情况" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "recommendationCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(1, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.recommendationCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1236474305
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "一面",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(2, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.arrangeInterviewCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1264118126
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewPercent",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "一面率",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offerCount",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(3, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.offerCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1100602002
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "serviceCharge",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "服务费",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      (_vm.type == 0 || _vm.type == 3) &&
      (_vm.dateType == "weekrange" || _vm.dateType == "daterange")
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "基本信息", width: "1000", fixed: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "time",
                      align: "left",
                      width: "110",
                      "show-overflow-tooltip": true,
                      label: "时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "projectName",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "项目名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerName",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "客户",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: true,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.customerArray,
                                },
                                on: { selectChange: _vm.selectCustomerChange },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1204060694
                    ),
                  }),
                  _vm.isCFUser
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "customerDomain",
                          align: "left",
                          width: "120",
                          "show-overflow-tooltip": true,
                          label: "所属领域",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _vm._v(_vm._s(row.customerDomain || "-")),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          214230646
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "businessGroupName",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "业务分组",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.businessGroupArray,
                                },
                                on: { selectChange: _vm.businessGroupChange },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1897618357
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "locations",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "地点",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerDeparmentChain",
                      align: "left",
                      width: "170",
                      "show-overflow-tooltip": true,
                      label: "事业群/事业部",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.departmentArray,
                                },
                                on: {
                                  selectChange: _vm.selectDepartmentChange,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2701558973
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "jobSeriesName",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "职位类别",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ column }) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  maxSelect: 5,
                                  enableCount: false,
                                  title: column.label,
                                  enableSearch: true,
                                  selectItem: _vm.jobArray,
                                },
                                on: { selectChange: _vm.selectJobChange },
                              }),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.jobSeriesName || "-"))]
                          },
                        },
                      ],
                      null,
                      false,
                      3655361437
                    ),
                  }),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "pm",
                          align: "left",
                          width: "68",
                          "show-overflow-tooltip": true,
                          label: "PM",
                        },
                      })
                    : _vm._e(),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "mc",
                          align: "left",
                          width: "68",
                          "show-overflow-tooltip": true,
                          label: "UL",
                        },
                      })
                    : _vm._e(),
                  _vm.type == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "firmTeamNames",
                          align: "left",
                          width: "180",
                          "show-overflow-tooltip": true,
                          label: "所属团队",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "headCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "HC数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.headCount || "-"))]
                          },
                        },
                      ],
                      null,
                      false,
                      2609910329
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "accessDate",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "接入时间",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.accessDate
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("accessDateFilter")(
                                          scope.row.accessDate
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1062946101
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "optimisticDegree",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "乐观程度",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.optimisticDegree == "1"
                                ? _c("span", [_vm._v("乐观")])
                                : scope.row.optimisticDegree == "2"
                                ? _c("span", [_vm._v("不乐观")])
                                : scope.row.optimisticDegree == "3"
                                ? _c("span", [_vm._v("谨慎")])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3483134741
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nodeImportance",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "节点重要性",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.nodeImportance == "1"
                                ? _c("span", [_vm._v("A类")])
                                : scope.row.nodeImportance == "2"
                                ? _c("span", [_vm._v("B类")])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      750936966
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "实际交付情况" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "recommendationCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(1, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.recommendationCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1236474305
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "一面",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(2, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.arrangeInterviewCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1264118126
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewPercent",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "一面率",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offerCount",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(3, row, row.pmId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.offerCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1100602002
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "serviceCharge",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "服务费",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type == 1 && _vm.dateType == "monthrange"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "基本信息" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "time",
                      align: "left",
                      width: "156",
                      "show-overflow-tooltip": true,
                      label: "时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mc",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "MC",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "firmTeamNames",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "所属一级团队",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "成员",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "交付目标" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "goalOfRecommandation",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goalOfInterview",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "面试",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goalOfOffer",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "实际交付情况" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "recommendationCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(1, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.recommendationCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3342418425
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "一面",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(2, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.arrangeInterviewCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2140215894
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewPercent",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "一面率",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offerCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(3, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.offerCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1640598826
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "obsoletedCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "飞单数",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type == 1 &&
      (_vm.dateType == "weekrange" || _vm.dateType == "daterange")
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "基本信息" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "time",
                      align: "left",
                      width: "156",
                      "show-overflow-tooltip": true,
                      label: "时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mc",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "MC",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "firmTeamNames",
                      align: "left",
                      width: "150",
                      "show-overflow-tooltip": true,
                      label: "所属一级团队",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      align: "left",
                      width: "120",
                      "show-overflow-tooltip": true,
                      label: "成员",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "实际数据" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "recommendationCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "推荐",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(1, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.recommendationCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3342418425
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "一面",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(2, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.arrangeInterviewCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2140215894
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arrangeInterviewPercent",
                      align: "left",
                      width: "100",
                      "show-overflow-tooltip": true,
                      label: "一面率",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offerCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "Offers",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "num",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(3, row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.offerCount))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1640598826
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "obsoletedCount",
                      align: "left",
                      width: "80",
                      "show-overflow-tooltip": true,
                      label: "飞单数",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }